/**
 * Licensed Materials - Property of HCL Technologies Limited.
 * (C) Copyright HCL Technologies Limited  2023.
 */

import { PriceDisplay } from '@/components/blocks/PriceDisplay';
import { OrderItemTableRowData } from '@/components/content/OrderItemTable/parts/Table';
import { useOrderItemTableRow } from '@/data/Content/OrderItemTable';
import { useStoreName } from '@/data/Content/StoreName';
import { ContentContext } from '@/data/context/content';
import { Typography } from '@mui/material';
import { FC, useContext, useMemo } from 'react';

const EMPTY_PRICE = { orderItemPrice: '', currency: '' };
export const OrderItemPrice: FC<{ showListPrice?: boolean }> = ({ showListPrice = false }) => {
	const {
		quantity,
		details,
		itemDetails,
		price: { orderItemPrice = '', currency = '' } = EMPTY_PRICE,
	} = useContext(ContentContext) as OrderItemTableRowData & ReturnType<typeof useOrderItemTableRow>;

	const { isBtemptd } = useStoreName();
	const priceColor = isBtemptd ? '#6d4f6d' : '#9C304D';

	const price = Number(orderItemPrice);

	const totalListPrice: number = useMemo(() => {
		if (showListPrice && Number(itemDetails.totalAdjustment.value) === 0) {
			const listPrice = details.prices.list;
			if (listPrice) {
				const total = listPrice * quantity.quantity;
				if (total > price) {
					return total;
				}
			}
		}
		return 0;
	}, [
		details.prices.list,
		itemDetails.totalAdjustment.value,
		price,
		quantity.quantity,
		showListPrice,
	]);

	const adjustedPrice: number = useMemo(() => {
		let shippingAdjustment = 0;

		itemDetails?.adjustment?.forEach((eachAdjustment) => {
			if (eachAdjustment.usage === 'Shipping Adjustment') {
				shippingAdjustment += Number(eachAdjustment.amount);
			}
		});

		if (Number(itemDetails.totalAdjustment.value) < 0) {
			return price + (Number(itemDetails.totalAdjustment.value) - shippingAdjustment);
		}

		return price + shippingAdjustment;
	}, [price, itemDetails.totalAdjustment.value, itemDetails.adjustment]);

	return (
		<Typography variant="h6" data-testid="offer-price" id="offer-price">
			{totalListPrice ? (
				<>
					<Typography variant="h6" component="span" sx={{ textDecoration: 'line-through' }}>
						<PriceDisplay currency={currency} min={totalListPrice} />
					</Typography>
					<Typography
						variant="h6"
						component="span"
						sx={totalListPrice ? { color: priceColor } : null}
					>
						<PriceDisplay currency={currency} min={price} />
					</Typography>
				</>
			) : adjustedPrice && adjustedPrice !== price ? (
				<>
					<Typography variant="h6" component="span" sx={{ textDecoration: 'line-through' }}>
						<PriceDisplay currency={currency} min={price} />
					</Typography>
					<Typography
						variant="h6"
						component="span"
						sx={adjustedPrice ? { color: priceColor } : null}
					>
						<PriceDisplay currency={currency} min={adjustedPrice} />
					</Typography>
				</>
			) : (
				<PriceDisplay currency={currency} min={price} />
			)}
		</Typography>
	);
};
