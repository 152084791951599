import { ContentRecommendation } from '@/components/content/ContentRecommendation';
import { SignedInDropMenu } from '@/components/content/Header/parts/AccountDropMenu/SignedInDropMenu';
import { SignedOutDropMenu } from '@/components/content/Header/parts/AccountDropMenu/SignedOutDropMenu';
import { headerAccountDropMenuSX } from '@/components/content/Header/styles/account/dropMenu';
import { wacoalHeaderAccountContainerSX } from '@/components/content/Header/styles/account/wacoalContainer';
import { useStoreName } from '@/data/Content/StoreName';
import { useNextRouter } from '@/data/Content/_NextRouter';
import { useLocalization } from '@/data/Localization';
import { useUser } from '@/data/User';
import { HeaderAccountMenuContext } from '@/data/context/headerAccountMenu';
import { ID } from '@/data/types/Basic';
import { useThemeSettings } from '@/styles/theme';
import { Switch } from '@/utils/switch';
import {
	Breakpoint,
	Button,
	ClickAwayListener,
	IconButton,
	Stack,
	Tooltip,
	useMediaQuery,
	useTheme,
} from '@mui/material';
import { FC, useCallback, useContext, useEffect, useState } from 'react';

type Props = {
	mobileBreakpoint: Breakpoint;
	isPageScrolled?: boolean;
	isHomepage?: boolean;
	id: ID;
};

export const WacoalHeaderAccount: FC<Props> = ({
	mobileBreakpoint = 'sm',
	isPageScrolled = false,
	isHomepage = false,
	id,
}) => {
	const AccountLabels = useLocalization('SignInPage');
	const HeaderLabels = useLocalization('Header');
	const theme = useTheme();
	const router = useNextRouter();
	const { getAdditive } = useThemeSettings();
	const isMobile = useMediaQuery(theme.breakpoints.down(mobileBreakpoint));
	const isTablet = useMediaQuery(theme.breakpoints.down('lg'));
	const { isOpen, setIsOpen } = useContext(HeaderAccountMenuContext);
	const { user } = useUser();
	const [isLoggedIn, setIsLoggedIn] = useState<boolean>(false);
	const { isWacoal } = useStoreName();

	const welcomeMessage = isLoggedIn
		? user?.firstName
			? HeaderLabels.Actions.WelcomeFirstName.t({ firstName: user?.firstName })
			: HeaderLabels.Actions.WelcomeNoFirstName.t()
		: AccountLabels.Title.t();

	// for CDN caching -- render this on client
	useEffect(() => {
		setIsLoggedIn(() => user?.isLoggedIn ?? false);
	}, [user, id]);

	const handleToolTip = useCallback(
		(action?: string) => () => {
			const getNewValue = () =>
				Switch(action)
					.case('open', () => true)
					.case('close', () => false)
					.case('toggle', () => !isOpen)
					.defaultTo(() => !isOpen);

			setIsOpen(getNewValue());
		},
		[isOpen, setIsOpen]
	);

	const handleClickAway = (event: MouseEvent | TouchEvent) => {
		if (event.target instanceof Element) {
			if (event.target.getAttribute('id') !== 'show-login-from-wishlist-menu') {
				setIsOpen(false);
			}
		}
	};

	/* useEffect(() => {
		if (!router.asPath) return;
		handleToolTip('close')();
	}, [handleToolTip, router.asPath]);*/

	return (
		<ClickAwayListener onClickAway={handleClickAway}>
			<Stack sx={wacoalHeaderAccountContainerSX(isPageScrolled, isHomepage)}>
				<Tooltip
					placement="bottom"
					open={isOpen}
					onClose={handleToolTip('close')}
					disableFocusListener
					disableHoverListener
					disableTouchListener
					componentsProps={{
						tooltip: {
							sx: headerAccountDropMenuSX,
						},
					}}
					PopperProps={{
						modifiers: [{ name: 'offset', options: { offset: isTablet ? [0, 23] : [0, 27] } }],
					}}
					title={isLoggedIn ? <SignedInDropMenu /> : <SignedOutDropMenu />}
				>
					<IconButton
						onClick={handleToolTip('toggle')}
						aria-label={welcomeMessage}
						sx={{
							height: isWacoal ? '22px' : '23px',
							width: isWacoal ? '22px' : '23px',
							padding: 0,
						}}
					>
						<ContentRecommendation
							key="AccountIcon"
							id={id}
							properties={{ emsName: 'AccountIcon' }}
						/>
					</IconButton>
				</Tooltip>
				{isMobile === false && isLoggedIn ? (
					<Button
						sx={getAdditive('coverTapTarget')}
						onClick={handleToolTip()}
						aria-label={welcomeMessage}
					></Button>
				) : null}
			</Stack>
		</ClickAwayListener>
	);
};
