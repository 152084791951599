/**
 * Licensed Materials - Property of HCL Technologies Limited.
 * (C) Copyright HCL Technologies Limited  2023.
 */

import { SxProps, Theme } from '@mui/material';

export const wacoalCarouselSlideSX: SxProps<Theme> = () => ({
	'&.carousel__slide': {
		textAlign: 'center',
		display: 'flex',
		justifyContent: 'center',
	},
	'.carousel__inner-slide': {
		position: 'relative',
		pb: '2px',
		display: 'flex',
		flexDirection: 'column',
		'.MuiCard-root': {
			flexGrow: 1,
		},
		'&:empty': {
			display: 'none',
		},
	},
});
