/**
 * Licensed Materials - Property of HCL Technologies Limited.
 * (C) Copyright HCL Technologies Limited  2023.
 */

import { CustomerService } from '@/components/content/CustomerService';
import { GlobalSpinner } from '@/components/content/GlobalSpinner';
import { Notifications } from '@/components/content/Notifications';
import { SessionErrorDialog } from '@/components/content/SessionErrorDialog';
import { layoutManifest } from '@/components/layouts/manifest';
import { Meta } from '@/data/Meta';
import { useStaticSettings } from '@/data/Settings';
import { SettingProvider } from '@/data/context/setting';
import { Layout } from '@/data/types/Layout';
import { ThemeSettingsProvider } from '@/styles/theme';
import { CssBaseline, SxProps, Theme, ThemeProvider } from '@mui/material';
import Head from 'next/head';
import { FC, useMemo } from 'react';

type PageProps = {
	meta: Meta;
	layout: Layout;
	theme: Theme;
	additives?: { [key: string]: SxProps | ((theme: Theme) => SxProps) };
	storeName?: string;
};

// eslint-disable-next-line complexity
export const PageBlock: FC<PageProps> = ({ meta, layout, theme, additives, storeName }) => {
	const Layout = layout && layoutManifest[layout.name] ? layoutManifest[layout.name] : () => null;

	const hrefLang = useMemo(() => {
		if (storeName?.toLowerCase() === 'wacoal canada') {
			return (
				<>
					<link rel="alternate" href="https://www.wacoal.ca" hrefLang="x-default" />
					<link rel="alternate" href="https://www.wacoal.ca" hrefLang="en-ca" />
					<link rel="alternate" href="https://www.wacoal.ca" hrefLang="fr-ca" />
				</>
			);
		} else if (storeName?.toLowerCase() === 'btemptd') {
			return (
				<>
					<link rel="alternate" href="https://btemptd.wacoal-america.com" hrefLang="x-default" />
					<link rel="alternate" href="https://btemptd.wacoal-america.com" hrefLang="en-us" />
				</>
			);
		} else {
			return (
				<>
					<link rel="alternate" href="https://www.wacoal-america.com" hrefLang="x-default" />
					<link rel="alternate" href="https://www.wacoal-america.com" hrefLang="en-us" />
				</>
			);
		}
	}, [storeName]);

	const iconImgName = useMemo(() => {
		// customization for wacoal
		if (storeName?.toLowerCase() !== 'btemptd') {
			return storeName?.toLowerCase().substring(0, 3);
		} else {
			return storeName?.toLowerCase();
		}
	}, [storeName]);

	return (
		<>
			<Head>
				<title>{meta?.title}</title>
				<meta name="description" content={meta?.description} />
				<meta name="keywords" content={meta?.keywords} />
				<meta name="theme-color" content="#000000" />
				<link
					rel="icon"
					type="image/x-icon"
					href={`/wcsstore/WacoalStorefrontAssetStore/upload/img/favicon_${
						storeName === 'Wacoal Canada' ? 'wacoal' : storeName?.toLowerCase()
					}.ico`}
				/>
				<link
					rel="shortcut icon"
					href={`/wcsstore/WacoalStorefrontAssetStore/upload/img/favicon_${
						storeName === 'Wacoal Canada' ? 'wacoal' : storeName?.toLowerCase()
					}.ico`}
				/>
				<link
					rel="icon"
					type="image/png"
					href={`/wcsstore/WacoalStorefrontAssetStore/upload/img/${iconImgName}16.png`}
					sizes="16x16"
				/>
				<link
					rel="icon"
					type="image/png"
					href={`/wcsstore/WacoalStorefrontAssetStore/upload/img/${iconImgName}32.png`}
					sizes="32x32"
				/>
				<link
					rel="icon"
					type="image/png"
					href={`/wcsstore/WacoalStorefrontAssetStore/upload/img/${iconImgName}48.png`}
					sizes="48x48"
				/>
				<link
					rel="apple-touch-icon-precomposed"
					href={`/wcsstore/WacoalStorefrontAssetStore/upload/img/${iconImgName}60.png`}
					sizes="60x60"
				/>
				<link
					rel="apple-touch-icon-precomposed"
					href={`/wcsstore/WacoalStorefrontAssetStore/upload/img/${iconImgName}72.png`}
					sizes="72x72"
				/>
				<link
					rel="apple-touch-icon-precomposed"
					href={`/wcsstore/WacoalStorefrontAssetStore/upload/img/${iconImgName}76.png`}
					sizes="76x76"
				/>
				<link
					rel="apple-touch-icon-precomposed"
					href={`/wcsstore/WacoalStorefrontAssetStore/upload/img/${iconImgName}114.png`}
					sizes="114x114"
				/>
				<link
					rel="apple-touch-icon-precomposed"
					href={`/wcsstore/WacoalStorefrontAssetStore/upload/img/${iconImgName}120.png`}
					sizes="120x120"
				/>
				<link
					rel="apple-touch-icon-precomposed"
					href={`/wcsstore/WacoalStorefrontAssetStore/upload/img/${iconImgName}144.png`}
					sizes="144x144"
				/>
				<link
					rel="apple-touch-icon-precomposed"
					href={`/wcsstore/WacoalStorefrontAssetStore/upload/img/${iconImgName}152.png`}
					sizes="152x152"
				/>
				{/* <link
					rel="icon"
					type="image/png"
					href={`/wcsstore/WacoalStorefrontAssetStore/upload/img/${iconImgName}180.png`}
					sizes="180x180"
				/>*/}
				{hrefLang}
			</Head>
			<CustomerService />
			<ThemeProvider theme={theme}>
				<CssBaseline />
				<ThemeSettingsProvider value={{ additives }}>
					<Layout layout={layout} />
					<GlobalSpinner />
					<Notifications />
					<SessionErrorDialog />
				</ThemeSettingsProvider>
			</ThemeProvider>
		</>
	);
};

export const StaticPageBlock: FC<PageProps> = ({ meta, layout, theme, additives }) => {
	const { settings } = useStaticSettings();

	return settings ? (
		<SettingProvider value={settings}>
			<PageBlock
				meta={meta}
				layout={layout}
				theme={theme}
				additives={additives}
				storeName={settings.storeName}
			/>
		</SettingProvider>
	) : null;
};
